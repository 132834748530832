export const SliceConstants = {
  GetMManager: "barcaSlice/manager",
  GetNews: "newsSlice/news",
  GetNewsView: "newsViewSlice/newsView",
  GetVacancie: "barcaSlice/vacancies",
  GetProject: "barcaSlice/Projects",
  GetInfoPage: "barcaSlice/infoPage",
  GetFinReports: "barcaSlice/finReports",
  GetFinReport: "barcaSlice/finReport",
  GetVacancieById: "barcaSlice/vacanciesById",
  ReplyVacancy: "barcaSlice/replyVacancy",
  SendApp: "barcaSlice/sendApp",
  Apartment: "barcaSlice/apartments",
  GetApartment: "barcaSlice/getApartments",
};

export const SliceName = {
  Auth: "auth",
  Barca: "barca",
  News: "news",
  NewsView: "newsView",
};
