import { useEffect } from "react";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { useNavigate, useParams } from "react-router-dom";
import { SEO } from "@utils/SEO";
import { useAppSelector } from "@hooks/useAppSelector";
import { getNewsViewSelector } from "@store/selectors/news";
import { getNewsView } from "@store/slices";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { ContainerUI } from "@ui-kit/container";
import { Routes } from "@enums/routes";
import { Loader } from "@ui-kit/loader";
import { getYouTubeVideoId, isYouTubeUrl } from "@utils/youtubeUrl";
import { SwiperUI } from "@ui-kit/swiperUI";
import styles from "./NewsView.module.scss";

export const NewsViewLayout = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { newsView, loading } = useAppSelector(getNewsViewSelector);
  const {
    banners,
    images,
    videos,
    title,
    description,
    text,
    updatedAt,
    newsDate,
  } = newsView || {};

  useEffect(() => {
    window.scrollTo(0, 0);

    if (id) {
      dispatch(getNewsView(id));
    }
  }, [id]);

  if (!loading && !newsView) {
    navigate(Routes.NOT_FOUND);
    return null;
  }

  return (
    <div className={styles.block}>
      {!loading && (
        <SEO
          titleNew={title}
          description={description}
          url={`${Routes.NEWS}/${id}`}
          image={banners?.banner}
        />
      )}

      <div className={styles.banner}>
        <div
          className={cn(styles.imageContainer, {
            [styles.notBanner]: !banners?.banner && !loading,
          })}
        >
          {banners?.banner && (
            <>
              <img
                src={banners.banner}
                className={styles.imageBlock}
                alt=""
                loading="lazy"
              />
              <img
                src={banners.banner}
                className={styles.imageBack}
                alt=""
                loading="lazy"
              />
            </>
          )}
          <ContainerUI>
            <div className={styles.btnBlock}>
              <button type="button" onClick={() => navigate(Routes.NEWS)}>
                <span className={styles.arrow} />
                {t("news.btnBack")}
              </button>
            </div>
          </ContainerUI>
        </div>
      </div>
      <ContainerUI>
        <div className={styles.wrapper}>
          {loading && <Loader fixed />}
          <div className={styles.header}>
            <h1 className={styles.title}>{title}</h1>
            <div className={styles.date}>{newsDate || updatedAt}</div>
          </div>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: text || "" }}
          />
        </div>
        {!!images?.length && <SwiperUI key={id} images={images} />}
        {videos?.video && (
          <div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p className={styles.mobile}>&nbsp;</p>
            <div className={styles.videoContent}>
              <div className={styles.videoBlock}>
                <div className={styles.videoPlayer}>
                  {isYouTubeUrl(videos.video) ? (
                    <iframe
                      className={cn(styles.video, styles.frame)}
                      src={`https://www.youtube.com/embed/${getYouTubeVideoId(
                        videos.video
                      )}`}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    />
                  ) : (
                    <video
                      key={id}
                      className={styles.video}
                      poster={videos.preview_path || ""}
                      controls
                    >
                      <source src={videos.video} type="video/mp4" />
                      Your browser does not support the video tag.
                      <track kind="captions" srcLang="en" label="English" />
                    </video>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </ContainerUI>
    </div>
  );
};
