export const AuthApi = {
  Manager: "/managements",
  Vacancie: "/vacancies",
  Projects: "/projects",
  InfoPage: "/info-pages",
  FinReports: "/finreports",
  FinReport: "/finreports/:id",
  News: "/news",
  NewsID: "news/:id",
  Application: "/jalgroup-applications",
  VacancyReply: "/vacancy-replies",
  Apartments: "/apartments",
};
