import { createSelector } from "@reduxjs/toolkit";
import { BarcaState } from "@store/slices";

type WithbarcaState = {
  barca: BarcaState;
};

export const barcaStateSelector = (state: WithbarcaState): BarcaState =>
  state.barca;

export const getLoadingSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.loading;
  }
);

export const getVacanciesSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.vacancies;
  }
);

export const getVacancieByIdSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.vacancieById;
  }
);

export const getFinReportsSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.finReports;
  }
);

export const getFinReportSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.finReport;
  }
);
