import { FC } from "react";
import { Helmet, HelmetProps } from "react-helmet-async";
import { useTranslation } from "react-i18next";

type SEOProps = {
  title?: string;
  titleNew?: string;
  description?: string;
  image?: string;
  url: string;
  keywords?: string;
} & HelmetProps;

export const SEO: FC<SEOProps> = (props) => {
  const { i18n } = useTranslation();
  const { title, titleNew, description, url, image, ...others } = props;

  const desc =
    description ||
    `Jal Group Asia – ведущая строительная
    компания в Кыргызстане. Основная специализация – строительство эксклюзивного
    клубного комплекса 'Барселона', представляющего собой новаторский проект в
    сфере недвижимости и развлечений. Компания также является гордым владельцем
    франшизы Barça Academy в Кыргызстане, предлагая молодым спортсменам
    уникальные возможности для развития в мире футбола. Узнайте больше о наших
    проектах и вкладе в развитие спортивной инфраструктуры региона на нашем
    официальном сайте.`;

  const img =
    image ||
    "https://s3.barcelona.kg/dev/09438912-2331.webp" ||
    "https://s3.barcelona.kg/dev/71682319-7028.webp";
  const urlBase = `https://jalgroupasia.kg${url || ""}`;

  return (
    <Helmet {...others}>
      <title>{!titleNew ? `Jal Group Asia - ${title}` : titleNew}</title>
      <meta name="description" content={desc} />
      <link rel="canonical" href={urlBase} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:image" content={img} />
      <meta name="twitter:url" content={urlBase} />
      <meta name="twitter:site" content="@FCB" />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content={img} />
      <meta property="og:url" content={urlBase} />
      <meta property="og:site_name" content="Jal Group Asia" />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={i18n.language || "ru"} />
      <meta property="og:locale:alternate" content="ru_RU" />

      <meta name="robots" content="index, follow" />
      <meta httpEquiv="content-language" content={i18n.language || "ru"} />
    </Helmet>
  );
};
