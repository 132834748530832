/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthApi } from "@constants/api";
import { SliceConstants, SliceName } from "@constants/slices";
import {
  FinReportTypes,
  IFinReport,
  IVacancies,
  QuarterTypes,
} from "@enums/slices";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import $api from "@utils/axios";
import { generatePath } from "react-router-dom";

interface IParams {
  skip?: number;
  limit?: number;
}

export interface BarcaState {
  loading: boolean;
  vacancies: {
    loading: boolean;
    amount: number;
    items: IVacancies[];
  };
  finReports: {
    loading: boolean;
    reports: IFinReport[];
    amount: number;
    error?: string;
  };
  finReport: {
    loading: boolean;
    report: IFinReport | null;
    error?: string;
  };
  vacancieById: IVacancies | null;
  replyVacancy: {
    loading: boolean;
    status?: "success" | "error";
  };
}

const initialState: BarcaState = {
  loading: false,
  vacancieById: null,
  replyVacancy: {
    loading: false,
    status: undefined,
  },
  finReports: {
    reports: [],
    amount: 0,
    loading: false,
  },
  finReport: {
    report: null,
    loading: false,
  },
  vacancies: {
    loading: false,
    amount: 0,
    items: [],
  },
};

export const getFinReports = createAsyncThunk(
  SliceConstants.GetFinReports,
  async (
    params: {
      finreport_type: FinReportTypes;
      quarter_type?: QuarterTypes;
      year?: number;
    } & IParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await $api.get(AuthApi.FinReports, {
        params,
      });
      return { items: data.items, amount: data.amount };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getFinReport = createAsyncThunk(
  SliceConstants.GetFinReport,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await $api.get(generatePath(AuthApi.FinReport, { id }));
      return data.finreport;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getVacancie = createAsyncThunk(
  SliceConstants.GetVacancie,
  async (
    params: {
      vacancy_type: "barca-experience" | "jalgroup";
    } & IParams,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await $api.get(AuthApi.Vacancie, {
        params,
      });
      return { items: data.items, amount: data.amount };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getVacancieById = createAsyncThunk(
  SliceConstants.GetVacancieById,
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const { data } = await $api.get(`${AuthApi.Vacancie}/${id}`);
      return data.vacancy;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const replyVacancy = createAsyncThunk(
  SliceConstants.ReplyVacancy,
  async (props: any, { rejectWithValue }) => {
    try {
      const { data } = await $api.post(AuthApi.VacancyReply, props);

      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

const barcaSlice = createSlice({
  name: SliceName.Barca,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVacancie.pending, (state) => {
      return {
        ...state,
        vacancies: {
          ...state.vacancies,
          loading: true,
        },
      };
    });
    builder.addCase(getVacancie.fulfilled, (state, { payload, meta }) => {
      return {
        ...state,
        vacancies: {
          loading: false,
          amount: payload.amount,
          items:
            (meta.arg.skip || 0) === 0
              ? payload.items
              : [...state.vacancies.items, ...payload.items],
        },
      };
    });
    builder.addCase(getVacancie.rejected, (state) => {
      return {
        ...state,
        vacancies: {
          loading: false,
          amount: 0,
          items: [],
        },
      };
    });
    builder.addCase(getVacancieById.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getVacancieById.fulfilled, (state, { payload }) => {
      return {
        ...state,
        loading: false,
        vacancieById: payload,
      };
    });
    builder.addCase(getVacancieById.rejected, (state) => {
      return {
        ...state,
        loading: false,
        vacancieById: null,
      };
    });
    builder.addCase(replyVacancy.pending, (state) => {
      return {
        ...state,
        replyVacancy: {
          loading: true,
          status: undefined,
        },
      };
    });
    builder.addCase(replyVacancy.fulfilled, (state) => {
      return {
        ...state,
        replyVacancy: {
          loading: false,
          status: "success",
        },
      };
    });
    builder.addCase(replyVacancy.rejected, (state) => {
      return {
        ...state,
        replyVacancy: {
          loading: false,
          status: "error",
        },
      };
    });
    builder.addCase(getFinReports.pending, (state) => {
      return {
        ...state,
        finReports: {
          ...state.finReports,
          loading: true,
        },
      };
    });
    builder.addCase(getFinReports.fulfilled, (state, { payload, meta }) => {
      return {
        ...state,
        finReports: {
          reports:
            (meta.arg.skip || 0) === 0
              ? payload.items
              : [...state.finReports.reports, ...payload.items],
          amount: payload.amount,
          loading: false,
        },
      };
    });
    builder.addCase(getFinReports.rejected, (state) => {
      return {
        ...state,
        finReports: {
          loading: false,
          amount: 0,
          reports: [],
        },
      };
    });
    builder.addCase(getFinReport.pending, (state) => {
      return {
        ...state,
        finReport: {
          ...state.finReport,
          loading: true,
        },
      };
    });
    builder.addCase(getFinReport.fulfilled, (state, { payload }) => {
      return {
        ...state,
        finReport: {
          report: payload,
          loading: false,
        },
      };
    });
    builder.addCase(getFinReport.rejected, (state) => {
      return {
        ...state,
        finReport: {
          loading: false,
          report: null,
        },
      };
    });
  },
});

export default barcaSlice.reducer;
