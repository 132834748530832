import { URLS_PROJECTS } from "@constants/urls";
import { Links } from "@enums/links";
import { Routes } from "@enums/routes";
import { Project } from "@models/project";
import { useTranslation } from "react-i18next";

type ReturnType = {
  data: Project[];
};

export const useData = (): ReturnType => {
  const { t } = useTranslation();

  const data = [
    {
      id: "01.",
      route: `${URLS_PROJECTS.experience}/jalal-abad`,
      image: [Links.academy, Links.academyMobile],
      title: t("newProject.jal"),
      soon: false,
    },
    {
      id: "02.",
      route: `${URLS_PROJECTS.experience}/bishkek`,
      title: t("newProject.bish"),
      image: [Links.team, Links.teamMobile],
      soon: true,
    },
    {
      id: "03.",
      route: Routes.MFC,
      image: [
        "https://s3.barcelona.kg/dev/82228280-4847.webp",
        "https://s3.barcelona.kg/dev/82228280-4847.webp",
      ],
      title: t("newProject.mfc"),
      soon: true,
    },
  ];

  return {
    data,
  };
};
