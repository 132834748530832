export enum Links {
  soon = "/icons/soon.svg",
  quote = "/icons/quote.svg",
  leftIcon = "/icons/leftIcon.svg",
  rightIcon = "/icons/rightIcon.svg",
  menuIcon = "/icons/menuIcon.svg",
  closeIcon = "/icons/closeIcon.svg",
  academyMobile = "https://s3.barcelona.kg/dev/72177656-3939.webp",
  mfc = "https://s3.barcelona.kg/dev/71682319-7028.webp",
  mfcMobile = "https://s3.barcelona.kg/dev/71414519-8027.webp",
  ball = "https://s3.barcelona.kg/dev/72177652-5017.webp",
  kepka = "https://s3.barcelona.kg/dev/71946524-8333.webp",
  aibek = "https://s3.barcelona.kg/dev/72177654-8635.webp",
  moneyDollar = `https://s3.barcelona.kg/dev/70983591-7655.webp`,
  line = "/icons/line.svg",
  doc = "/icons/doc.svg",
  arrowTime = "/images/arrow.svg",
  clock = "/images/clock.svg",
  academyDetails = "https://s3.barcelona.kg/dev/72177653-4915.webp",
  jalAcademy = "https://s3.barcelona.kg/dev/71946527-4712.webp",
  bishAcademy = "https://s3.barcelona.kg/dev/71946520-6380.webp",
  barcaStore = "https://s3.barcelona.kg/dev/72177659-7344.webp",
  bishCafe = "https://s3.barcelona.kg/dev/71946522-0926.webp",
  bishMuseum = "https://s3.barcelona.kg/dev/71946523-9862.webp",
  arrowLeft = "/icons/arrowLeft.svg",
  arrowRight = "/icons/arrowRight.svg",
  academy = "https://s3.barcelona.kg/dev/72177649-9295.webp",
  mfc1 = "https://s3.barcelona.kg/dev/73528256-3610.webp",
  mfc2 = "https://s3.barcelona.kg/dev/73611035-8157.webp",
  mfc3 = "https://s3.barcelona.kg/dev/73582388-5320.webp",
  mfc4 = "https://s3.barcelona.kg/dev/73626005-3556.webp",
  mfc5 = "https://s3.barcelona.kg/dev/71682326-1071.webp",
  mfc6 = "https://s3.barcelona.kg/dev/71593194-5683.webp",
  mfc7 = "https://s3.barcelona.kg/dev/71593203-2160.webp",
  mfc8 = "https://s3.barcelona.kg/dev/71593192-3667.webp",
  mfc9 = "https://s3.barcelona.kg/dev/71593204-8350.webp",
  mfc10 = "https://s3.barcelona.kg/dev/71593211-0563.webp",
  mfc11 = "https://s3.barcelona.kg/dev/71414517-3948.webp",
  mfc12 = "https://s3.barcelona.kg/dev/71414514-5374.webp",
  mfc13 = "https://s3.barcelona.kg/dev/71414516-5621.webp",
  mfc14 = "https://s3.barcelona.kg/dev/73640018-8214.webp",
  mfc15 = "https://s3.barcelona.kg/dev/73650991-0133.webp",
  logo = "https://s3.barcelona.kg/static/logo.svg",
  logoHeader = "/icons/barca.svg",
  bigLogo = "https://s3.barcelona.kg/static/bigLogo.svg",
  leftDecorators = "https://s3.barcelona.kg/static/leftDecorators.svg",
  preview = "https://s3.barcelona.kg/static/preview.svg",
  previewBarca = "https://s3.barcelona.kg/static/previewBarca.svg",
  nextSlider = "https://s3.barcelona.kg/static/nextSlider.svg",
  prevSlider = "https://s3.barcelona.kg/static/prevSlider.svg",
  swiperLine = "https://s3.barcelona.kg/static/swiperLine.svg",
  backMain = "https://s3.barcelona.kg/static/backMain.png",
  barcaImage = "https://s3.barcelona.kg/static/barcaImage.jpeg",
  kovych = "/icons/hello.svg",
  whiteArrow = "/images/whiteArrow.svg",
  team = "https://s3.barcelona.kg/dev/70983600-8428.webp",
  teamMobile = "https://s3.barcelona.kg/dev/70983602-6825.webp",
  swimming = "/icons/swimming.svg",
  peoples = "/icons/peoples.svg",
  gantel = "/icons/gantel.svg",
  kind = "/icons/kind.svg",
  card1 = "/icons/card1",
  card2 = "/icons/card2",
  card3 = "/icons/card3",
  football = "/icons/football.svg",
  parking = "/icons/parking.svg",
  cliniks = "/icons/cliniks.svg",
  zone = "/icons/zone.svg",
  barcaIcon = "/icons/smallIcon.svg",
  club = "https://s3.barcelona.kg/static/club.jpg",
  mapImage = "https://s3.barcelona.kg/static/map.png",
  slider1 = "https://s3.barcelona.kg/static/slider1.jpeg",
  slider2 = "https://s3.barcelona.kg/static/slider2.jpeg",
  slider3 = "https://s3.barcelona.kg/static/slider3.jpeg",
  slider4 = "https://s3.barcelona.kg/static/slider4.jpeg",
  slider5 = "https://s3.barcelona.kg/static/slider5.jpeg",
  leftDec = "https://s3.barcelona.kg/static/videoLeftDec.svg",
  rightDec = "https://s3.barcelona.kg/static/videoRightDec.svg",
  coachesBack = "https://s3.barcelona.kg/static/logoCoaches.svg",
  blueBack = "https://s3.barcelona.kg/static/blueBack.png",
  facebook = "/icons/facebook.svg",
  instagram = "/icons/instagram.svg",
  whatsapp = "/icons/whatsapp.svg",
  twitter = "/icons/twitter.svg",
  twitterX = "/icons/twitterX.png",
  banner = "https://s3.barcelona.kg/static/banner.jpg",
  in = "https://s3.barcelona.kg/static/OFF.svg",
  arrow = "/icons/arrow.svg",
  gym = "https://s3.barcelona.kg/static/gym.jpg",
  form = "https://s3.barcelona.kg/static/form.jpg",
  present = "https://s3.barcelona.kg/static/present.jpg",
  shop = "/icons/shop.svg",
  rec = "/icons/rec.svg",
  jalGroup = "https://s3.barcelona.kg/static/jalGroup.svg",
  mockJal = "https://s3.barcelona.kg/static/mockJal.svg",
  logoBarca = "https://s3.barcelona.kg/static/barcaPng.png",
  footer = "https://s3.barcelona.kg/static/footerLog.svg",
  blackLogo = "https://s3.barcelona.kg/static/blackLogo.svg",
  call = "/icons/call.svg",
  sms = "/icons/sms.svg",
  sms2 = "/icons/sms2.svg",
  management = "/icons/management.svg",
  headerVideo = "https://s3.barcelona.kg/static/IMG_0480.mp4",
  jalGroupSmall = "/icons/jalGroupLogoSmall.svg",
  menu = "/icons/menu.svg",
  mobileHeader1 = "/icons/mobileHeaderimage1.svg",
  mobileBackgroundMain = "https://s3.barcelona.kg/static/back.png",
  mobileBackgroundHeader = "https://s3.barcelona.kg/static/mobileBackgroundHeader.png",
  mockJalsmall = "https://s3.barcelona.kg/static/foto.svg",
  jalGroupSvg = "/icons/jalGroupSvg.svg",
  location = "/icons/location.svg",
  location2 = "/icons/location2.svg",
  location3 = "/icons/locations3.svg",
  buildings = "/icons/buildings.svg",
  arrowSquare = "/icons/arrow-square.svg",
  calendar = "/icons/calendar.svg",
  house = "/icons/house.svg",
  money = "/icons/money.svg",
  arrowDownSelect = "/icons/arrow-square-down.svg",
  jalGroupBig = "/icons/jalGroupBig.svg",
  jalGroupGrey = "https://s3.barcelona.kg/static/greyLogo.png",
  car = "/icons/Car.svg",
  bath = "/icons/Bathtub.svg",
  arrowOut = "/icons/ArrowsOut.svg",
  heart = "/icons/heart.svg",
  share = "/icons/share.svg",
  plus = "/icons/plus.svg",
  menu2 = "/icons/menu2.svg",
  barcaNewsCardphoto = "https://s3.barcelona.kg/static/barcanews.png",
  nitro = "https://s3.barcelona.kg/static/nitro.png",
  shoro = "https://s3.barcelona.kg/static/shoro.png",
  demir = "https://s3.barcelona.kg/static/demirbank.png",
  mbank = "https://s3.barcelona.kg/static/mbank.png",
  akbashat = "https://s3.barcelona.kg/static/akbashat.jpeg",
  akbashat2 = "https://s3.barcelona.kg/static/akbashat2.jpeg",
  akbashat3 = "https://s3.barcelona.kg/static/akbashat3.jpeg",
  akbashat4 = "https://s3.barcelona.kg/static/akbashat4.jpeg",
  barcaStadium = "https://s3.barcelona.kg/static/barcaStadium.jpeg",
  barcaStadium2 = "https://s3.barcelona.kg/static/barcaStadium2.jpeg",
  blockASmall = "https://s3.barcelona.kg/static/blockA.jpeg",
}
