import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { LayoutUI } from "@ui-kit/layout";
import NewHeader from "@ui-kit/newHeader/NewHeader";
import { Footer } from "@ui-kit/footer/Footer";
import { AppRouter } from "./providers";

export default function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="wrapperApp" translate="no">
      <NewHeader />
      <div className="mainApp">
        <LayoutUI>
          <AppRouter />
        </LayoutUI>
      </div>
      <Footer />
    </div>
  );
}
