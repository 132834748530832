export enum LocalStorageKey {
  AccessToken = "at",
  RefreshToken = "rt",
  AcceptCookie = "accept_cookie",
  Filial = "filial",
  Lang = "lang",
  VacancyIds = "vacancyIds",
}

export enum LocalStorageValues {
  Bishkek = "bishkek",
  JalalAbad = "jalal-abad",
}
