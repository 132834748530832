import { LocalStorageKey } from "@enums/localStorage";
import { LocalStorage } from "@localStorage/localStorage";
import axios, { AxiosRequestHeaders } from "axios";

const $api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

$api.interceptors.request.use(
  (config: any) => {
    if (typeof window !== "undefined") {
      const lang = LocalStorage.getItem(LocalStorageKey.Lang);

      const headers: Partial<AxiosRequestHeaders> = {
        "Accept-Language": lang ?? "ru",
      };

      return {
        ...config,
        headers: {
          ...headers,
          ...config.headers,
        },
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default $api;
