// Функция для проверки, является ли URL ссылкой на YouTube
export const isYouTubeUrl = (url: string) => {
  return url.includes("youtube.com") || url.includes("youtu.be");
};

// Функция для извлечения идентификатора видео из URL YouTube
export const getYouTubeVideoId = (url: string) => {
  const match = url.match(/(?:\?v=|\/embed\/|\/\d\/|\.be\/)([\w\d_-]{11})/);
  return match ? match[1] : null;
};
