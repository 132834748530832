import { useTranslation } from "react-i18next";
import { SEO } from "@utils/SEO";
import { News } from "./news";
import { FinReport } from "./finReport";
import { SliderBlock } from "./sliderBlock";
import { OurMission } from "./ourMission";
import { Advantages } from "./advantages";
import { OurProjects } from "./OurProjects";

const NewMainLayout = () => {
  const { t } = useTranslation();

  return (
    <div>
      <SEO title={t("header.main")} url="" />

      <SliderBlock />
      <OurMission />
      <Advantages />
      <OurProjects />
      <FinReport />
      <News />
    </div>
  );
};

export default NewMainLayout;
