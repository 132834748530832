export const URLS_PROJECTS = {
  experience: process.env.REACT_APP_EXP_URL || "",
  jalGroup: process.env.REACT_APP_JAL_URL || "",
  store: process.env.REACT_APP_STORE_URL || "",
  tel: process.env.REACT_APP_TEL_URL || "",
  mail: process.env.REACT_APP_MAIL_URL || "",
  instagram: process.env.REACT_APP_INSTAGRAM_URL || "",
  facebook: process.env.REACT_APP_FACEBOOK_URL || "",
  whatsapp: process.env.REACT_APP_WHATSAPP_URL || "",
};
